import React, { useState, useEffect, createContext, useContext } from "react"
import { Coffee } from 'react-feather'
import { toast, Slide } from 'react-toastify'
import Avatar from '@components/avatar'

import { useDispatch } from "react-redux"
import { handleLogout } from "@store/actions/auth"

export const UserContext = createContext()

export const Token = () => {
  const [token] = useContext(UserContext)
  return token
}

export const SetToken = () => {
  const [, setToken] = useContext(UserContext)
  return setToken
}

export const UserProvider = ({ children }) => {
  const dispatch = useDispatch()
  const [token, setToken] = useState(null)
  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)

  const refreshToken = async () => {
    try {
      const response = await fetch('https://wslite-backend.herokuapp.com/api/v0.01/refresh', {
        method: 'POST',
        headers: {Authorization: `bearer ${token}`}
      })

      const data = await response.json()
      setToken(data.access_token)
      localStorage.setItem('token', data.access_token)
      setRefresh(false)
    } catch (error) {
      console.log('Could not refresh user token')
      setRefresh(false)
    }
  }

  const ToastContent = () => (
    <Fragment>
      <div className='toastify-header'>
        <div className='title-wrapper'>
          <Avatar size='sm' color='success' icon={<Coffee size={12} />} />
          <h6 className='toast-title font-weight-bold'>Session time out</h6>
        </div>
      </div>
      <div className='toastify-body'>
        <span>Redirecting to Login Page</span>
      </div>
    </Fragment>
  )

  useEffect(() => {
    const token = localStorage.getItem('token')
    setToken(token)

    const checkData = async () => {
      if (token) {
        const response = await fetch('https://wslite-backend.herokuapp.com/api/v0.01/user-profile', {
          method: 'GET',
          headers: {Authorization: `bearer ${token}`}
        })

        const data = await response.json()
        if (data.status !== 'error') {
          setLoading(false)
        } else {
          localStorage.removeItem('token')
          dispatch(handleLogout())
          // window.location.replace('/login')
          setLoading(false)
        }
      } else {
        dispatch(handleLogout())
        // window.location.replace('/login')
        setLoading(false)
      }

    }

    checkData()
  }, [])

  setInterval(() => {
    setRefresh(true)
  }, 18600000)

  setInterval(() => {
    localStorage.removeItem('token')
    dispatch(handleLogout())
    window.location.replace('/login')
    setLoading(false)
  }, 21600000)

  return (
    <UserContext.Provider value={[token, setToken]}>
      <>
      {refresh && (
        <div style={{zIndex: '1000', position: 'absolute', right: 20, marginTop: '20px', background: '#fff', padding: '20px 10px', borderRadius: '5px'}}>
          <span>Session is about to end. Are you still using the app?</span>
          <button style={{borderRadius: '5px', color: '#fff', background: '#185874', border: 'none', padding: '10px 20px', marginLeft: '10px'}} onClick={refreshToken}>Yes</button>
        </div>
      )}
      {!loading && children}
      </>
    </UserContext.Provider>
  )
}
